@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Aileron;
  src: url('/fonts/Aileron-Regular.woff');
}

@font-face {
  font-family: AileronItalic;
  src: url('/fonts/Aileron-Italic.otf');
}

@font-face {
  font-family: AileronBold;
  src: url('/fonts/Aileron-Bold.woff');
}

@font-face {
  font-family: AileronSemiBold;
  src: url('/fonts/Aileron-SemiBold.woff');
}

@font-face {
  font-family: Besley;
  src: url('/fonts/Besley-Regular.ttf');
}

@font-face {
  font-family: BesleyMedium;
  src: url('/fonts/Besley-Medium.ttf');
}

@font-face {
  font-family: BesleySemiBold;
  src: url('/fonts/Besley-SemiBold.ttf');
}

@font-face {
  font-family: BesleyItalic;
  src: url('/fonts/Besley-Italic.ttf');
}

html, body {
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.growth-rings-bg {
  background-image: url("/images/bg-upper-growth-rings.svg"), url("/images/bg-growth-rings.svg");
  background-position: top right, left bottom;
  background-repeat: no-repeat, no-repeat;
}

.masonry-grid {
  display: flex;
  margin-left: -1rem; /* gutter size offset */
  width: auto;
}
.masonry-grid_column {
  padding-left: 0.5rem; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.masonry-grid_column > * { /* change div to reference your elements you put in <Masonry> */
  background: transparent;
  margin-bottom: 0.5rem;
}
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}